.logoheader {
  height: 23px;
  margin-top: 2px;
  margin-left: 24px;
  margin-right: 10px;
}
.w-100 {
  width: 100%;
}
.display-block {
  display: block;
}
.details-list-items {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.details-list-items li:last-child {
  border: none !important;
}
.uploader .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.MuiCardContent-root div div {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  transition: border-color 250ms ease;
}
@media (max-width: 450px) {
  .logoheader {
    height: 15px;
    margin-left: 10px;
  }
}
